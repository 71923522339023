import axios from 'axios'

export const saveLocation = async (locationData) => {
  const { name, place_id, description, email, isOptIn } = locationData
  const API_URI = process.env.REACT_APP_API_URI || 'http://localhost:3000/dev'
  const options = {
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY,
    },
  }
  if (!name || !place_id || !description || (isOptIn && !email)) {
    return false
  }

  try {
    return await axios.post(`${API_URI}/location`, locationData, options)
  } catch (error) {
    //TODO: handle these....
    console.log(error)
    return false
  }
}
