import React from 'react'

import { useSubmissionStyles } from './submissionStyles'

export const Submission = ({ data }) => {
  const classes = useSubmissionStyles()

  return (
    <div>
      <p className={classes.message}>
        Thank you!{' '}
        {data.email && (
          <span>
            You are <i>amazing</i>.
          </span>
        )}
      </p>
    </div>
  )
}
