import { makeStyles } from '@material-ui/core/styles'

export const useSubmissionStyles = makeStyles((theme) => {
  return {
    message: {
      fontSize: '24px !important',
      fontWeight: 'bold',
      letterSpacing: '-.04em',
    },
  }
})
