import { makeStyles } from '@material-ui/core/styles'

export const useFooterStyles = makeStyles((theme) => {
  return {
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 'auto',
      '@media (max-width:480px)': {
        display: 'block',
        marginTop: '35px',
      },
      '& p': {
        display: 'flex',
      },
      '& span': {
        padding: '0 15px',
      },
    },
    legalText: {
      fontSize: '14px !important',
    },
    instaIcon: {
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
  }
})
