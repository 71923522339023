import einaBoldWoff2 from './eina-01-bold.woff2'
import einaRegularWoff2 from './eina-01-regular.woff2'
import einaSemiboldWoff2 from './eina-01-semibold.woff2'

export const einaBold = {
  fontFamily: 'Eina',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${einaBoldWoff2}) format('woff2')`,
}

export const einaRegular = {
  fontFamily: 'Eina',
  fontStyle: 'regular',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `url(${einaRegularWoff2}) format('woff2')`,
}

export const einaSemibold = {
  fontFamily: 'Eina',
  fontStyle: 'semi-bold',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `url(${einaSemiboldWoff2}) format('woff2')`,
}
