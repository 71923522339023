import { makeStyles } from '@material-ui/core/styles'

export const useHomeStyles = makeStyles((theme) => {
  return {
    root: {
      '& form': {
        width: '80%',
        '@media (max-width:680px)': {
          width: '100%',
        },
      },
      '& .MuiButton-root': {
        width: '100%',
        maxWidth: '300px',
        padding: '10px 16px',
        fontWeight: 700,
        '@media (max-width:480px)': {
          width: '100%',
          maxWidth: '100%',
        },
      },
      '& .MuiAutocomplete-root': {
        width: '100%',
      },
      '& .MuiTextField-root': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
      },
      '& .MuiButton-contained span.emoji': {
        paddingLeft: '15px',
      },
      '& .MuiFormControlLabel-label': {
        fontWeight: '300',
        lineHeight: '100%',
      },
      '& .MuiFormHelperText-contained.Mui-error': {
        fontSize: '14px',
      },
      '& p': {
        fontSize: '16px',
      },
    },
    title: {
      ...theme.typography.h1,
      // '@media (max-width:680px)': {
      //   maxWidth: '80%',
      // },
      '& span': {
        display: 'block',
      },
      '& span.rotate': {
        display: 'inline-block',
      },
    },
  }
})
