import React from 'react'
import { Home } from '../Home'

/**
 * Router docs
 * https://github.com/Paratron/hookrouter/blob/master/src-docs/pages/en/README.md
 */

export const routes = {
  '/': () => <Home />,
}
