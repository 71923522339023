import React, { useState, useRef, useMemo, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import throttle from 'lodash/throttle'
import { makeStyles } from '@material-ui/core/styles'
import { renderOption } from './renderOption'
import { loadScript } from './loadScript'

const autocompleteService = { current: null }
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

let bounds = null

export const GooglePlaceSearch = ({ error, onChange, disabled }) => {
  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const loaded = useRef(false)
  const classes = useStyles()
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      )
    }

    loaded.current = true
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback)
      }, 200),
    []
  )

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
      bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng({
          lat: 30.209763,
          lng: -97.833571,
        }),
        new window.google.maps.LatLng({
          lat: 30.399465,
          lng: -97.651591,
        })
      )
    }

    if (inputValue === '' || inputValue.length < 2) {
      setOptions(value ? [value] : [])
      return
    }

    fetch(
      {
        input: `${inputValue}`,
        bounds,
        componentRestrictions: { country: 'us' },
        types: ['establishment'],
      },
      (results) => {
        let newOptions = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    )
  }, [inputValue, fetch, value])

  return (
    <Autocomplete
      name="location"
      id="google-place-search"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      disabled={disabled}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(_, newValue) => {
        setValue(newValue)
        onChange(newValue)
      }}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      noOptionsText="Type to search"
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={error}
            disabled={disabled}
            label="Where is it?"
            variant="outlined"
            helperText={error && 'Where is it in Austin?'}
            fullWidth
          />
        )
      }}
      renderOption={(options) => renderOption(options, classes)}
    />
  )
}
