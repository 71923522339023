import React from 'react'
import logo from '../../assets/img/taco.jpg'
import { useHeaderStyles } from './styles'

export const Header = () => {
  const classes = useHeaderStyles()

  return (
    <header>
      <a href="/" className={classes.logo}>
        <img
          src={logo}
          style={{ height: 50, width: 50 }}
          alt="Taco Crawl ATX Logo"
        ></img>
        <span>
          TACO CRAWL <b>ATX</b>
        </span>
      </a>
    </header>
  )
}
