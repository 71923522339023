import { createMuiTheme } from '@material-ui/core/styles'
import { einaBold, einaRegular, einaSemibold } from '../../assets/fonts/eina'

export const theme = createMuiTheme({
  typography: {
    fontFamily: 'Eina, Helvetica, Arial, sans-serif',
    h1: {
      fontSize: '4rem',
      fontWeight: '700',
      lineHeight: '100%',
      letterSpacing: '-.04em',
      '@media (max-width:680px)': {
        fontSize: '2.5rem',
        maxWidth: '80%',
      },
      '@media (max-width:321px)': {
        fontSize: '2rem',
      },
    },
  },
  palette: {
    primary: {
      main: '#2A9D8F',
      contrastText: '#E7E5DF',
    },
    secondary: {
      main: '#44BBA4',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [einaBold, einaRegular, einaSemibold],
        body: {
          background: '#E7E5DF',
        },
      },
    },
  },
})
