import React from 'react'
import InstagramIcon from '@material-ui/icons/Instagram'
import { useFooterStyles } from './styles'

export const Footer = () => {
  const classes = useFooterStyles()

  return (
    <footer className={classes.footer}>
      <p>
        Follow us on:{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/tacocrawlatx/"
          title="Taco Crawl ATX on Instragram"
        >
          <InstagramIcon className={classes.instaIcon} />
        </a>
      </p>
      <p className={classes.legalText}>&copy; 2020 Taco Crawl ATX</p>
    </footer>
  )
}
