import { makeStyles } from '@material-ui/core/styles'

export const useHeaderStyles = makeStyles((theme) => {
  return {
    logo: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: '#000',
      fontWeight: '500',
      '& img': {
        height: '50px',
        width: '50px',
        borderRadius: '25px',
        overflow: 'hidden',
        border: 'solid 1px',
      },
      '& span': {
        padding: '0 10px',
        fontSize: '16px',
        '& b': {
          color: theme.palette.secondary.main,
        },
      },
    },
  }
})
