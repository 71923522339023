import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { GooglePlaceSearch } from '../../components/GooglePlaceSearch/GooglePlaceSearch'
import { saveLocation } from '../../utils/request'
import { Submission } from '../../components/Submission'
import { useHomeStyles } from './styles'

export const Home = () => {
  const { register, handleSubmit, errors, control } = useForm()
  const [isOptIn, setIsOptIn] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submission, setSubmission] = useState(null)
  const classes = useHomeStyles()

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>Where are the best tacos in Austin, TX?</h1>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(async (data) => {
          const {
            reason,
            location: {
              place_id,
              description,
              structured_formatting: { main_text },
            },
            email,
          } = data
          setIsSubmitting(true)
          setSubmission(null)
          const res = await saveLocation({
            name: description,
            text: main_text,
            place_id,
            description: reason,
            isOptIn,
            email,
          })
          if (res) {
            setSubmission(res.data)
          }
          setIsSubmitting(false)
        })}
      >
        <p>
          As long-time Austinites, we've come to know a good taco spot or 10
          over the years. Now, we're interested to know what you think. Where is
          your hidden gem or that one you always come back for again and again?
          We'll plot them all on a map so the next time you're ready to crawl a
          few Austin taco hot-spots, you'll know where to turn.
        </p>
        <Controller
          as={<GooglePlaceSearch />}
          control={control}
          name="location"
          rules={{ required: true }}
          onChange={(_, newValue) => {
            return newValue
          }}
          defaultValue={null}
          disabled={isSubmitting}
          error={!!errors.location}
        />

        <TextField
          error={!!errors.reason}
          // value={description}
          // onChange={(e) => setDescription(e.target.value)}
          id="reason"
          name="reason"
          disabled={isSubmitting}
          label="Why is it your favorite?"
          rows={4}
          inputProps={{ maxLength: 500 }}
          variant="outlined"
          inputRef={register({ required: true })}
          helperText={!!errors.reason && 'But wait, can you tell us why?'}
          multiline
        />
        <div>
          <FormControlLabel
            control={
              <Checkbox
                disabled={isSubmitting}
                checked={isOptIn}
                color="primary"
                onChange={(e) => setIsOptIn(e.target.checked)}
                name="checkedA"
              />
            }
            label="Sign up to be a part of our limited beta?"
          />
        </div>
        {isOptIn && (
          <TextField
            error={!!errors.email}
            helperText={errors.email && errors.email.message}
            id="email"
            name="email"
            disabled={isSubmitting}
            inputRef={register({
              required: isOptIn,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Hey, that's not an email address...",
              },
            })}
            label="Email Address"
            variant="outlined"
          />
        )}
        <div style={{ paddingTop: '15px' }}>
          <Button
            disabled={isSubmitting}
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
          >
            Save
            <span className="emoji" role="img" aria-label="taco">
              🌮
            </span>
          </Button>
          {submission && <Submission data={submission} />}
        </div>
      </form>
    </div>
  )
}
