import React from 'react'
import { useRoutes } from 'hookrouter'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import './global.css'
import { theme } from './theme'
import { routes } from './routes'
import { Header } from '../../components/Header'
import { Footer } from '../../components/Footer'

export const App = () => {
  const routeResult = useRoutes(routes)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <main>
        {routeResult || (
          <div>
            <p>404 Not Found</p>
          </div>
        )}
      </main>
      <Footer />
    </ThemeProvider>
  )
}
